<template>
  <div class="InventoryTransferDetail">
    <PageHeader title="调拨单详情" />
    <div class="flex j-between a-center p-tb-20 p-lr-20 f14">
      <div>调拨名称： {{ infoData.InventoryName }}</div>
      <!--      <div>调出仓库： {{ infoData.INStockName }}</div>-->
      <!--      <div>调入仓库： {{ infoData.OUTStockName }}</div>-->
      <div>状态： {{ infoData.strStatus }}</div>
      <div>操作人： {{ infoData.CreateUserName }}</div>
      <div>单据备注： {{ infoData.Remark }}</div>
      <div>调出时间： {{ infoData.WriteTime }}</div>
    </div>
    <div class="m-bottom-20 m-lr-20">
      <el-table
        id="testTable"
        ref="multipleTable"
        v-loading="tableLoading"
        class="TableModel27"
        :header-cell-style="headerCStyle"
        :data="infoData.lst"
        tooltip-effect="dark"
        show-header
        height="calc(100vh - 360px)"
        row-key="id"
        :border="true"
      >
        <el-table-column align="center" prop="InvCode" label="物料编号" show-overflow-tooltip />
        <el-table-column align="center" prop="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column align="center" prop="BrandName" label="品牌" show-overflow-tooltip />
        <el-table-column align="center" prop="MaterialTypeName" label="物料类型" show-overflow-tooltip />
        <el-table-column align="center" prop="Specification" label="规格" show-overflow-tooltip />
        <el-table-column align="center" prop="StockUnitN" label="单位" show-overflow-tooltip />
        <el-table-column align="center" prop="LotNum" label="批号/序列号" show-overflow-tooltip />
        <el-table-column align="center" prop="LotNo" label="入库批次" show-overflow-tooltip />
        <el-table-column align="center" prop="ExpiredDate" label="效期" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.setDateFormate(scope.row.ExpiredDate) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="INStockName" label="调出仓库" show-overflow-tooltip />
        <el-table-column align="center" prop="OUTStockName" label="调入仓库" show-overflow-tooltip />
        <el-table-column align="center" prop="StockNum" label="可调拨数量" show-overflow-tooltip />
        <el-table-column align="center" prop="InventoryNum" label="调拨数量" show-overflow-tooltip />
        <!--        <el-table-column align="center" prop="strStatus" label="状态" show-overflow-tooltip />-->
        <el-table-column align="center" prop="WriteTime" label="调出时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.WriteTime), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="InventoryUserName" label="操作人" show-overflow-tooltip />
        <el-table-column align="center" prop="DINum" label="UDI码" show-overflow-tooltip />
        <el-table-column align="center" prop="StorageConditionName" label="贮存条件" show-overflow-tooltip />
      </el-table>
    </div>
    <div class="flex j-between a-center m-lr-20">
      <div class="flex j-start">
        <span>品牌合计： {{ totas }}</span><span class="m-left-20">调拨数量：{{ nums }}</span>
      </div>
      <div class="pagination-container flex j-end">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      <!-- <el-button type="primary" icon="el-icon-printer" @click="print">打印</el-button> -->
      </div>
    </div>
  </div>
</template>
<script>
import Index from '@/minxin/management/InventoryTransferDetail'
export default Index
</script>
