import PageHeader from '@/components/PageHeader'
import tableMixin from '@/minxin/table-minxin'
const Index = {
  name: 'InventoryTransferDetail',
  mixins: [tableMixin],
  components: {
    PageHeader
  },
  mounted() {
    // this.getTableDataAsync()
    console.log(this.$route.params)
  },
  data() {
    return {
      infoData: { lst: [] }
    }
  },
  computed: {
    totas() {
      return this.infoData.lst.length
    },
    nums() {
      let num = 0
      this.infoData.lst.map(item => {
        if (item.InventoryNum) {
          num += item.InventoryNum * 1
        }
      })
      return num
    }
  },
  methods: {
    async getTableDataAsync(page, pageSize) {
      console.log(21331231232131)
      const response = await this.$api.GetInventoryTransferInfo({
        IDX: this.$route.params.IDX
      })
      this.infoData = response.Message
      console.log(response)
      return response
    }
  }

}
export default Index
